import * as React from 'react';
import Layout from '../components/Layout';
import Seo from '../components/Seo';

const NotFoundPage = () => (
  <Layout>
    <Seo title="Not found" description="404 - page not found" />
    <div className="container">
      <h1>Oh no!</h1>
      <h3>We can&apos;t seem to find the page you&apos;re looking for.</h3>
      <br />
    </div>
  </Layout>
);

export default NotFoundPage;
